import {Component, OnInit} from '@angular/core';
import {faArrowRight, faMinus, faPaintRoller, faSlidersH, faStar} from '@fortawesome/free-solid-svg-icons';
import {storeMenuItems} from '@app/_shared/component/shopyan-layout/left-menu/menu';
import {ActivatedRoute} from '@angular/router';
import {FeatureAction} from "@app/_shared/enumeration/feature-action.enum";
import {PlanFeatureType} from "@app/_shared/enumeration/plan-feature-type.enum";
import {StoreCacheService} from "@app/_shared/service/store-cache.service";
import {TranslateService} from "@ngx-translate/core";
import {PlanType} from "@app/_shared/model/plan/plan-type.model";
import {FeatureType, getStoreId} from "@app/_shared";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {


  menuItems: any;

  faSlidersH = faSlidersH;
  faStar = faStar;

  featureAction = FeatureAction;

  planType = PlanType;
  storeID = getStoreId();
  //TODO add ,NOTIFICATIONS_PARAMETERS and ,SHIPPING_ZONES_PARAMETERS when activated
  settingFeatures = 'GENERAL_PARAMETERS,CHECKOUT_PARAMETERS,DOMAINES_PARAMETERS,LANGAGES_CURRENCY_PARAMETERS,TAXES_PARAMETERS,SEO_PARAMETERS';
  mapSettingsUrl = new Map([
    [FeatureType.CHECKOUT_PARAMETERS, "checkout"],
    [FeatureType.DOMAINES_PARAMETERS, "domains"],
    // [FeatureType.NOTIFICATIONS_PARAMETERS, "notifications"],
    [FeatureType.LANGAGES_CURRENCY_PARAMETERS, "languages"],
    // [FeatureType.SHIPPING_ZONES_PARAMETERS, "shipping"],
    [FeatureType.TAXES_PARAMETERS, "tax"],
    [FeatureType.SEO_PARAMETERS, "seo"]]);
  planFeatures: Map<PlanFeatureType, boolean>;
  settingsUrl = '/store/' + this.storeID + '/settings'

  builderURL = environment.builderUrl;

  constructor(private activatedRoute: ActivatedRoute,
              protected storeCacheService: StoreCacheService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.planFeatures = new Map(this.storeCacheService.planFeatures.map((obj) => [obj.type, obj.enabled]));
    // this.orderService.count().subscribe(response => this.ordersCount = response, error => this.ordersCount = 0);
    // this.customerService.count().subscribe(response => this.customersCount = response, error => this.ordersCount = 0);
    this.menuItems = JSON.parse(JSON.stringify(storeMenuItems)).map((item: any) => {
      item.feature = item.feature || item.subMenus?.map((item: any) => item.feature).toString();
      item.url = this.getFirstAvailableUrl(item);
      item.subMenus?.forEach((subItem: any) => subItem.url = this.getURL(subItem.url));
      return item;
    });
    if (this.storeCacheService.employee && !this.storeCacheService.storePermissions.features.some(value => value.feature == FeatureType.GENERAL_PARAMETERS && value.actionFeatures.includes(FeatureAction.READ))) {
      for (const [key, value] of this.mapSettingsUrl) {
        if (this.storeCacheService.storePermissions.features.some(value => value.feature == key && value.actionFeatures.includes(FeatureAction.READ))) {
          this.settingsUrl = this.settingsUrl + "/" + value;
          return;
        }
      }
    }
  }

  getFirstAvailableUrl(menu: any) {
    if (!this.storeCacheService.employee ||
      (!menu.feature.includes(',') && this.storeCacheService.storePermissions.features.some(value => value.feature == menu.feature && value.actionFeatures.includes(FeatureAction.READ))))
      return this.getURL(menu.url);
    const firstAvailableFeature = menu.feature.split(',').find((feature: string) => this.storeCacheService.storePermissions.features.some(value => value.feature == feature && value.actionFeatures.includes(FeatureAction.READ)));
    return this.getURL(menu.subMenus?.find((sub: any) => sub?.feature == firstAvailableFeature)?.url || menu.url);
  }

  getURL(configurableURL: string): string {
    return configurableURL.replace(':storeID', this.storeID);
  }

  //
  // getCount(menuItem: any) {
  //   if(menuItem.hasCounter) {
  //     if(menuItem.code == 'MENU_ORDER'){
  //       return this.ordersCount;
  //     }
  //     if(menuItem.code == 'MENU_CUSTOMER'){
  //       return this.customersCount;
  //     }
  //   }
  //   return '';
  // }

  getMenuTranslation(code: string): string {
    return this.translate.instant('ENUMS.ROOT_MENU.' + code);
  }

  protected readonly faArrowRight = faArrowRight;
  protected readonly faMinus = faMinus;
  protected readonly faPaintRoller = faPaintRoller;
}
