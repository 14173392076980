// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  conf: {
    paypal: true,
    payzone: true,
    stripeConnect: true,
    team: true,
    cloudflare: false
  },
  domainUrl: 'youshopia.com',
  contextPath: 'https://admin-ui.sookify.com',
  productApiUrl: 'https://admin-api.svc.sookify.com/products/admin/api',
  mediaURL: 'https://admin-api.svc.sookify.com/products/media/',
  storeApiUrl: 'https://admin-api.svc.sookify.com/store/admin/api',
  publicStoreApiUrl: 'https://api.svc.sookify.com/store',
  discountApiUrl: 'https://admin-api.svc.sookify.com/discounts/admin/api',
  orderApiUrl: 'https://admin-api.svc.sookify.com/orders/admin/api',
  socketUrl: 'wss://admin-api.svc.sookify.com/ws/websocket',
  builderUrl: 'https://builder.sookify.com',
  storeURL: 'https://store-ui.sookify.com',
  payzoneUrl: 'https://payment.payzone.ma/pwthree/launch',
  keycloak: {
    // Url of the Identity Provider
    url: 'https://auth.svc.sookify.com/auth',
    realm: 'shopyan-auth',
    clientId: 'shopyan-client',
  },
  googleApisCredentials: {
    client_id: '1047597798134-m3du4bappnnepgmje9g3189okocsccrf.apps.googleusercontent.com',
    callback: 'https://admin-ui.sookify.com/callback'
  }
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
