import {Component, OnDestroy} from '@angular/core';
import {faArrowLeft, faDollarSign, faLock} from "@fortawesome/free-solid-svg-icons";
import {
  Country,
  extractErrorMessagesFromResponse,
  PaymentInfos,
  Plan,
  PlanCheckout,
  PlanDiscount,
  PlanInvoiceType,
  PlanPaymentType,
  RefDataService,
  YanLoaderService,
  YanToastrService
} from "@app/_shared";
import {combineLatest, Observable, Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {StoreSettingsService} from "@app/_shared/service/store-settings.service";
import {StoreForm} from "@app/_shared/model/store/store.model";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class StoreCheckoutCommonComponent implements OnDestroy {

  subscription: Subscription = new Subscription();

  protected faArrowLeft = faArrowLeft;
  faLock = faLock;
  faDollarSign = faDollarSign;


  /**
   * Data
   */
  formGroup: FormGroup;
  selectedPlan: Plan;

  automaticDiscountValue: number;
  appliedDiscountValue: number;
  total: number;
  discount: PlanDiscount;

  /**
   * Screen manager
   */
  loading = false;
  discountLoading = false;
  isBusiness = false;
  discountCode: string;
  discountError: string | null;
  countries: Country[] = [];
  planType: PlanInvoiceType;

  /**
   * Enums
   */
  paymentType = PlanPaymentType;
  invoicingType = PlanInvoiceType;

  protected constructor(private formBuilder: FormBuilder,
                        protected yanToastrService: YanToastrService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected yanLoaderService: YanLoaderService,
                        protected translate: TranslateService,
                        private refDataService: RefDataService,
                        protected storeSettingsService: StoreSettingsService) {
  }

  init(planId: string, invoiceType: PlanInvoiceType): void {
    this.planType = invoiceType;
    this.refDataService.getCountries().subscribe(data => this.countries = data);

    const planCall:Observable<any> = !this.getStoreForm() ? this.storeSettingsService.getPlan(planId, invoiceType) :
      this.storeSettingsService.getPlanForCreation(planId, invoiceType)

    this.yanLoaderService.startLoader('checkout-spinner');
    let sub = combineLatest([
      planCall,
      this.storeSettingsService.getPaymentInfos()
    ]).subscribe({
          next: ([planResult, pIResult]: [any, PaymentInfos])=> {
            this.selectedPlan = planResult;
            this.isBusiness = pIResult.company != undefined;
            this.initForm(planId, invoiceType, pIResult);
            this.calculateTotal();
            this.yanLoaderService.stopLoader('checkout-spinner');
          }, error: (error: any) => {
            this.yanToastrService.error(extractErrorMessagesFromResponse(error));
            this.yanLoaderService.stopLoader('checkout-spinner');
          }
        });
    this.subscription.add(sub);
  }

  /**
   * Init form
   * @private
   */
  private initForm(planId?: string, invoiceType?: PlanInvoiceType, paymentInfos?: PaymentInfos): void {

    this.formGroup = this.formBuilder.group({
      selectedPlan: [planId],
      planType: [invoiceType],
      firstName: [paymentInfos?.firstName],
      lastName: [paymentInfos?.lastName, Validators.required],
      address: [paymentInfos?.address, Validators.required],
      city: [paymentInfos?.city, Validators.required],
      zipCode: [paymentInfos?.zipCode],
      country: [paymentInfos?.country, Validators.required],
      company: [paymentInfos?.company],
      vatNumber: [paymentInfos?.vatNumber],
      paymentType: [null, Validators.required],
      discountCode: []
    });
  }

  /**
   * Check / Uncheck is business checkbox
   */
  toggleIsBusiness(): void {
    let companyControl = this.formGroup.get('company') as FormControl;
    if (this.isBusiness) {
      companyControl.setValidators([Validators.required]);
    } else {
      companyControl.setValue(null);
      companyControl.clearValidators();
      this.formGroup.get('vatNumber')?.setValue(null);
    }
    companyControl.updateValueAndValidity();
  }


  /**
   * Appy discount code
   */
  applyDiscountCode(): void {
    if (this.discountCode) {
      this.discountLoading = true;
      let sub = this.storeSettingsService.getBillingDiscount(this.discountCode, this.planType, this.selectedPlan.id).subscribe(
        {
          next: (response: any) => {
            this.discount = response;
            this.calculateTotal();
            this.discountCode = '';
            this.discountLoading = false;
            this.discountError = null;
          }, error: (err: any) => {
            this.discountCode = '';
            this.discountLoading = false;
            if (err.error && err.error.errors && err.error.errors.length > 0) {
              this.discountError = err.error.errors[0];
            } else {
              this.discountError = "Invalid code";
            }
          }
        });
      this.subscription.add(sub);
    }
  }


  /**
   * Calculate total
   */
  calculateTotal(): void {

    this.automaticDiscountValue = 0;
    if (this.selectedPlan.discount) {
      if (this.selectedPlan.discount.percent) {
        this.automaticDiscountValue = +(this.selectedPlan.price * this.selectedPlan.discount.percent / 100).toFixed(2);
      } else if (this.selectedPlan.discount.fixedAmount) {
        this.automaticDiscountValue = this.selectedPlan.discount.fixedAmount;
      }
    }

    this.appliedDiscountValue = 0;
    if (this.discount) {
      if (this.discount.percent) {
        this.appliedDiscountValue = +(this.selectedPlan.price * this.discount.percent / 100).toFixed(2);
      } else if (this.discount.fixedAmount) {
        if(this.discount.forFixedPrice) {
          this.appliedDiscountValue = this.selectedPlan.price - this.discount.fixedAmount;
        } else {
          this.appliedDiscountValue = this.discount.fixedAmount;
        }
      }
    }

    this.total = this.selectedPlan.price;
    this.total -= this.automaticDiscountValue;
    this.total -= this.appliedDiscountValue;
    this.total = +this.total.toFixed(2);
  }

  protected abstract getStoreForm(): StoreForm | undefined;

  /**
   * Submit form
   */
  submit() {

    let storeForm = this.getStoreForm();

    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      document.getElementById('page-anchor')?.scrollIntoView({behavior: 'smooth', block: 'start'});
      this.yanToastrService.error(this.translate.instant('COMMON.FILL_ALL_REQUIRED_FIELD'));
      return;
    }

    let data = new PlanCheckout();
    Object.assign(data, this.formGroup.value);
    if (this.discount) {
      data.discountCode = this.discount.discountCode;
    }
    data.storeForm = storeForm;
    this.loading = true;
    const call:Observable<any> = !storeForm ? this.storeSettingsService.billingCheckout(data) :
                                              this.storeSettingsService.storeCreationCheckout(data)

    let sub = call.subscribe(
      {
        next: (response: any) => {
          if ([PlanPaymentType.CREDIT_CARD, PlanPaymentType.PAYPAL].includes(data.paymentType)) {
            window.location.href = response.url;
            return;
          } else if (data.paymentType == PlanPaymentType.LOCAL_CREDIT_CARD) {
            this.launchPayzone(response);
            return;
          }
          this.loading = false;
          this.yanToastrService.success(this.translate.instant('STORE_CHECKOUT.SUCCESSFULLY_PAYMENT'));
          this.router.navigateByUrl(`/my-sites`).then();
        }, error: (error: any) => {
          this.loading = false;
          this.yanToastrService.error(extractErrorMessagesFromResponse(error));
        }
      });
    this.subscription.add(sub);

  }

  launchPayzone(data: any) {
    const form = document.createElement('form');
    form.action = environment.payzoneUrl;
    form.method = 'POST';

    // Champ caché pour le payload
    const payloadInput = document.createElement('input');
    payloadInput.type = 'hidden';
    payloadInput.name = 'payload';
    payloadInput.value = data.jsonPayload;

    // Champ caché pour la signature
    const signatureInput = document.createElement('input');
    signatureInput.type = 'hidden';
    signatureInput.name = 'signature';
    signatureInput.value = data.signature;

    // Ajouter les champs cachés au formulaire
    form.appendChild(payloadInput);
    form.appendChild(signatureInput);

    // Ajouter le formulaire au DOM
    document.body.appendChild(form);

    // Soumettre le formulaire
    form.submit();
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
