<div id="left-menu">

  <div id="lm-header">
  </div>

  <div id="lm-content">
    <div class="menu-options">

      <ng-container *ngIf="menuItems">
        <div *ngFor="let menuItem of menuItems"
             class="option-group {{menuItem.className}}"
             routerLinkActive="active"
             [class.has-sub-menu]="menuItem.subMenus && menuItem.subMenus.length > 0">
          <ng-container *ngIf="!menuItem.planFeature || planFeatures.get(menuItem.planFeature)">
            <ng-container *hasPermission="[menuItem.feature, featureAction.READ]">
              <a [routerLink]="menuItem.url" class="op-label">
                <div class="ic"></div>
                <span>{{getMenuTranslation(menuItem.code)}}</span>
                <!--                          <div class="menu-alert" *ngIf="menuItem.hasCounter">{{getCount(menuItem)}}</div>-->
                <!--                <div class="arrow"></div>-->
              </a>
              <div class="op-items animated tdFadeInDown"
                   *ngIf="menuItem.subMenus && menuItem.subMenus.length > 0">
                <ng-container *ngFor="let subMenuItem of menuItem.subMenus">
                  <ng-container *ngIf="!subMenuItem.planFeature || planFeatures.get(subMenuItem.planFeature)">
                    <ng-container *hasPermission="[subMenuItem.feature, featureAction.READ]">
                      <a [routerLink]="subMenuItem.url" class="op-item"
                         routerLinkActive="active">
                        <div class="arrow right">
                          <fa-icon [icon]="faArrowRight"></fa-icon>
                        </div>
                        <div class="arrow minus">
                          <fa-icon [icon]="faMinus"></fa-icon>
                        </div>
                        <span>{{getMenuTranslation(subMenuItem.code)}}</span>
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>


    </div>
  </div>

  <div id="lm-footer">

    <a [routerLink]="[settingsUrl]" class="setting"
       routerLinkActive="active"
       *hasPermission="[settingFeatures,featureAction.READ]">
      <div class="ic">
        <fa-icon [icon]="faSlidersH"></fa-icon>
      </div>
      <span>{{ 'COMMON.SHOPYAN_LAYOUT.SETTING' | translate }}</span>
    </a>

    <a [routerLink]="['/store/'+storeID+'/settings/billing/plans']"
       class="premium"
       *ngIf="!storeCacheService.employee && storeCacheService.planType != planType.PREMIUM">
      <div class="ic">
        <fa-icon [icon]="faStar"></fa-icon>
      </div>
      <span>{{ 'COMMON.SHOPYAN_LAYOUT.GO_PREMIUM' | translate }}</span>
    </a>

    <a [href]="builderURL+'/'+storeID" target="_blank" class="customize"
       *hasPermission="[settingFeatures,featureAction.READ]">
      <div class="ic">
        <fa-icon [icon]="faPaintRoller"></fa-icon>
      </div>
      <span>{{ 'ONLINE_SITE.CUSTOMIZATION.CUSTOMIZE_STORE_BUTTON' | translate }}</span>
    </a>

  </div>

</div>
