export class Application {
  id: string;

  name: string;

  description: string;

  details: string;

  icon: string;

  gallery: string[];

  totalReviews: number;

  rate: number;

  totalInstall: number;

  installed: boolean;

  type: ApplicationType;

  comingSoon: boolean;
}


export enum ApplicationType {
  SALES_POP = 'SALES_POP',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  SNAPCHAT_PIXEL = 'SNAPCHAT_PIXEL',
  TIKTOK_PIXEL = 'TIKTOK_PIXEL',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  COUNTDOWN = 'COUNTDOWN',
  GOOGLE_SHEET = 'GOOGLE_SHEET',
  NETWORK_ACCESS = 'NETWORK_ACCESS',
  FACEBOOK_CONVERSION_API = 'FACEBOOK_CONVERSION_API',
  WHATSAPP = 'WHATSAPP',
  CATHEDIS = 'CATHEDIS',
  SHIPSEN = 'SHIPSEN',
  X10LEAD = 'X10LEAD',
  CLOAKYAN = 'CLOAKYAN',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
  BUNDLE = 'BUNDLE'
}

export enum PixelType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER'
}

export enum ConversionType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT'
}

export enum PixelConversionType {
  PURCHASE = 'PURCHASE',
  LEAD = 'LEAD'
}

