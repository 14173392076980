import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Pageable} from '@app/_shared';

@Injectable({providedIn: 'root'})
export class BundleService {

  constructor(private http: HttpClient) {
  }


  /**
   * Filter bundles
   */
  public filterBundles(filter: string, pageable: Pageable): Observable<any> {
    let url = `${environment.storeApiUrl}/bundles/filter`;
    let params = pageable ? pageable.getParams() : new HttpParams();
    if (filter) {
      params = params.append('filter', filter);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.get(url, httpOptions);
  }

}

